import Api from '@/services/Index'

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/exam/schedules', config)
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/exam/schedules/' + id)
}

const getOfStudent = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/exam/schedules/student/' + id)
}

const store = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/exam/schedules', formData)
}

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.put('/exam/schedules/' + id, formData)
}

const del = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.delete('/exam/schedules/' + id)
}

const getInstructor = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/exam/schedules/instructor', config)
}

const updateObservers = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.put('/exam/schedules/' + id + '/observers', formData)
}

const fileImport = async (semesterId, file) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/exam/schedules/import/' + semesterId, file)
}

const downloadAsExcel = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/exam/schedules/excel-export', {...config, responseType: 'arraybuffer'})
}

const showStudents = (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/exam/schedules/student-list/' + id)
}

const getStudentListReport = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/exam/schedules/student-list-report', config)
}

const examSchedulesStudentPlacement = (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/exam/schedules/student/placement', formData)
}

const examSchedulesDoubleCourseControl = (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/exam/schedules/double-course-control', formData)
}

const overlapReport = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/exam/schedules/overlap-report', config)
}

const examSchedulesFindTime = (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/exam/schedules/find/time', formData)
}
const downloadAsExcel1 = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/exam/schedules/double-course-control', {...config})
}

const verticalTransition = (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/registrations/vertical-transfer-yok-report', {...formData, responseType: 'arraybuffer'})
}



export default {
    getAll,
    store,
    update,
    get,
    getOfStudent,
    del,
    getInstructor,
    updateObservers,
    fileImport,
    downloadAsExcel,
    examSchedulesStudentPlacement,
    showStudents,
    getStudentListReport,
    examSchedulesDoubleCourseControl,
    overlapReport,
    examSchedulesFindTime,
    downloadAsExcel1,
    verticalTransition
}
